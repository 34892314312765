import { getDefaultPaymentMethod } from 'containers/billing/queries/queries/getDefaultPaymentMethod';
import { getPaymentMethodData } from 'containers/billing/queries/queries/getPaymentMethodData';
import { billingMutationKeys } from 'containers/billing/queries/queryKeys';
import { API } from 'utilities/api/account';
import { createNXMutation } from 'utilities/methods/tanstack/createMutation';

/**********************************************************************************************************
 *   MUTATION START
 **********************************************************************************************************/
export const setPaymentMethodCallback = createNXMutation({
    mutationKey: billingMutationKeys.setPaymentMethodCallback(),
    mutationFn: API.user.payment_method.callback.POST,
    onSuccess: () => {
        getDefaultPaymentMethod.invalidate();
        getPaymentMethodData.invalidate(); //This is done because it might remove all the cards
    },
});
