/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT_API } from 'utilities/api/account';
import { getDataFromData } from 'utilities/methods/commonActions';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 */
const getAvailablePaymentMethodsBoilerPlate = createQueryBoilerPlate({
    service: 'billing',
    serviceKey: 'availablePaymentMethods',
    APIRoute: () => () => ACCOUNT_API.user.payment_methods.GET(),
    defaultOptions: {
        staleTime: Infinity,
        cacheTime: Infinity,
        retryOnMount: false,
        retry: 3,
        onError: () => handleDefaultErrorNotification,
    },
});

const { getDefaults } = getAvailablePaymentMethodsBoilerPlate;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 * User Payment Methods (Credit Card, PayPal, BPay, EFT)
 */
function useGetAvailablePaymentMethodsQuery(options = {}) {
    return useQuery({
        ...getDefaults(),
        ...options,
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

useGetAvailablePaymentMethodsQuery.selectFilters = {
    /**
     * Select function to get payment_method_id
     */
    getPaymentMethodID: (paymentMethod) => {
        return (response) => {
            const responseData = getDataFromData(response);
            const foundPaymentMethod = responseData?.find?.(({ name }) => name === paymentMethod);
            return foundPaymentMethod?.id;
        };
    },
};

export { getAvailablePaymentMethodsBoilerPlate, useGetAvailablePaymentMethodsQuery };
