import { addCreditCard } from 'containers/billing/queries/mutations/addCreditCard';
import { removePaymentMethodData } from 'containers/billing/queries/mutations/removePaymentMethodData';
import { setDefaultPaymentMethod } from 'containers/billing/queries/mutations/setDefaultPaymentMethod';
import { setPaymentMethodCallback } from 'containers/billing/queries/mutations/setPaymentMethodCallback';
import { setPrimaryCreditCard } from 'containers/billing/queries/mutations/setPrimaryCreditCard';
import { getAvailablePaymentMethods } from 'containers/billing/queries/queries/getAvailablePaymentMethods';
import { getDefaultPaymentMethod } from 'containers/billing/queries/queries/getDefaultPaymentMethod';
import { getPaymentMethodData } from 'containers/billing/queries/queries/getPaymentMethodData';
import { getPayPalToken } from 'containers/billing/queries/queries/getPayPalToken';

/**********************************************************************************************************
 *   QUERY TREE START
 **********************************************************************************************************/
export const _billingQuery = Object.freeze({
    getAvailablePaymentMethods,
    getDefaultPaymentMethod,
    setDefaultPaymentMethod,
    getPaymentMethodData,
    paymentId: {
        removePaymentMethodData,
        setPrimaryCreditCard,
        getPayPalToken,
        setPaymentMethodCallback,
    },
    addCreditCard,
});
