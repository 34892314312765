import axios from 'axios';
import { nxAxios } from 'store/nxAxios';

import { urlQueryBuilder } from 'utilities/methods/commonActions';

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
const CancelToken = axios.CancelToken;

export let CancelGetTwoFactor;
export let cancelGetSLAEvents;
export let cancelGetSLAServices;
export let cancelSearchAccountEmails;
export let cancelGetStates;
export let cancelGetContact;
export let cancelGetWalletTransactions;
export let cancelSearchWalletTransactions;
export let cancelCalculateCashOut;

export namespace API {
    export namespace user {
        export namespace auth {
            export namespace code {
                export type ReturnType = Promise<Artah.User.Auth.Code.POST._200>;
            }
        }

        export namespace creditCard {
            export namespace POST {
                export type Params = {
                    card_number: string;
                    card_year: string;
                    card_month: string;
                    card_cvv: string;
                    card_holder_name: string;
                    card_type: string;
                };
            }
        }

        export namespace payment_method {
            export namespace callback {
                export namespace POST {
                    export type Params = {
                        payment_method_id: number;
                        attributes: {
                            type: string;
                            payment_nonce: string;
                            account_id: string;
                        };
                    };
                }
            }
        }
    }
    export namespace account {
        export namespace GET {
            export namespace mercury {
                export namespace bypass {
                    export type ReturnType = Artah.Login.Bypass.ID.GET.NXReturnType;
                    export type Params = string;
                }
            }

            export namespace subaccount {
                export namespace exists {
                    export type ReturnType = Artah.User.AdditionalUser.GET.NXReturnType;
                    export type Params = string;
                }
                export namespace approve {
                    export type ReturnType = Artah.User.AdditionalUser.GET.NXReturnType;
                    export type Params = string;
                }
            }
            export namespace serviceGroups {
                export namespace list {
                    export type ReturnType = Artah.ServiceGroups.POST.NXReturnType;
                }
            }
        }

        export namespace POST {
            export namespace serviceGroup {
                export namespace add {
                    export type Params = {
                        name: string;
                        description?: string;
                    };
                    export type ReturnType = Artah.ServiceGroups.POST.NXReturnType;
                }
            }
        }

        export namespace PUT {
            export namespace serviceGroup {
                export namespace edit {
                    export type Params = {
                        name: string;
                        description?: string;
                        serviceGroupId: number | null;
                    };
                    export type ReturnType = Artah.ServiceGroups.PUT.NXReturnType;
                }
            }
        }

        export namespace DELETE {
            export namespace serviceGroup {
                export namespace deleteGroup {
                    export type Params = number;
                    export type ReturnType = Artah.ServiceGroups.DELETE.NXReturnType;
                }
            }
        }
    }
}

export const API = {
    /**********************************************************************************************************
     *   ACCOUNT API
     **********************************************************************************************************/
    user: {
        creditCard: {
            POST: (attributes: API.user.creditCard.POST.Params) => {
                return nxAxios.post(`/api/user/credit-card`, {
                    type: 'payment-method',
                    attributes,
                });
            },
        },
        payment_method_data: {
            /**
             * CCP needs to be able to retrieve a list of payment method data
             */
            GET: (): Artah.User.PaymentMethodData.GET.NXReturnType => {
                return nxAxios.get(`/api/user/payment-method-data`);
            },

            /**
             * CCP needs to be able to save new payment method data
             */
            POST: (data) => {
                return nxAxios.post(`/api/user/payment-method-data`, {
                    type: 'payment-method-data',
                    attributes: data,
                });
            },

            single: {
                /**
                 * CCP needs to be able to retrieve a single payment method data
                 */
                GET: (id: number) => {
                    return nxAxios.get(`/api/user/payment-method-data/${id}`);
                },

                /**
                 * CCP needs to be able to set the default payment method (data) on a users account
                 */
                PUT: (id: number): Artah.User.PaymentMethodData.ID.PUT.NXReturnType => {
                    return nxAxios.put(`/api/user/payment-method-data/${id}`, {
                        type: 'payment-method-data',
                        attributes: {
                            apply_to_all_services: true,
                        },
                    });
                },

                /**
                 * CCP needs to be able to delete payment method data
                 */
                DELETE: (id: number): Artah.User.PaymentMethodData.ID.DELETE.NXReturnType => {
                    return nxAxios.delete(`/api/user/payment-method-data/${id}`);
                },
            },
        },
        payment_methods: {
            /**
             * CCP needs to be able to retrieve a list of payment methods
             */
            GET: (): Artah.User.PaymentMethods.GET.NXReturnType => {
                return nxAxios.get(`/api/user/payment-methods`);
            },
        },

        payment_method: {
            /**
             * Get default payment method
             */
            GET: (): Artah.User.PaymentMethod.GET.NXReturnType => {
                return nxAxios.get(`/api/user/payment-method`);
            },

            /**
             * Set default payment method
             */
            PUT: (payment_method_id: number): Artah.User.PaymentMethod.ID.PUT.NXReturnType => {
                return nxAxios.put(`/api/user/payment-method/`, {
                    type: 'payment-method',
                    attributes: {
                        apply_to_all_services: true,
                        payment_method_id,
                    },
                });
            },

            token: {
                /**
                 * CCP needs to be able to generate a payment token for the PayPal (or another token-based) payment module
                 */
                GET: (payment_method_id: number): Artah.User.PaymentMethod.ID.Token.GET.NXReturnType => {
                    return nxAxios.get(`/api/user/payment-method/${payment_method_id}/token`);
                },
            },
            callback: {
                /**
                 * CCP needs to be able to handle a payment module callback as part of a token-based payment
                 */
                POST: ({
                    payment_method_id,
                    attributes,
                }: API.user.payment_method.callback.POST.Params): Artah.User.PaymentMethod.ID.callback.POST.NXReturnType => {
                    return nxAxios.post(`/api/user/payment-method/${payment_method_id}/callback`, { attributes });
                },
            },

            generate: {
                POST: (id: number): Artah.User.PaymentMethod.ID.Generate.POST.NXReturnType =>
                    nxAxios.post(`/api/user/payment-method/${id}/generate`, { type: 'setup' }),
            },
        },
        invoice: {
            bulk: {
                email: {
                    /**
                     * Bulk Resend Invoice Emails
                     */
                    POST: (attributes) => {
                        return nxAxios.post('/api/user/invoice/bulk/email', {
                            type: 'invoice',
                            attributes,
                        });
                    },
                },
            },

            merge: {
                /**
                 * Display a listing of user unpaid invoices.
                 */
                GET: () => {
                    return nxAxios.get(`/api/user/invoice/merge`);
                },

                /**
                 * Perform invoice merge
                 */
                POST: (attributes) => {
                    return nxAxios.post('/api/user/invoice/merge-invoices', {
                        type: 'invoice',
                        attributes,
                    });
                },
            },

            check_merge_invoices: {
                /**
                 * Calculates the new amount due for merging invoices
                 */
                POST: ({ attributes, signal }) => {
                    return nxAxios.post(
                        '/api/user/invoice/check-merge-invoices',
                        {
                            type: 'invoice',
                            attributes,
                        },
                        {
                            signal,
                        }
                    );
                },
            },

            queriedList: {
                /**
                 * get account invoices with query parameters
                 */
                GET: (queryParams) => {
                    const apiQuery = `/api/user/invoice${urlQueryBuilder(queryParams)}`;
                    return nxAxios.get(apiQuery);
                },
            },

            search: {
                /**
                 * Search for an invoices by keyword
                 *
                 * @param {object} queryParams
                 * @param {AbortSignal} signal
                 * @param {'id' | 'name'} type
                 */
                GET: (queryParams, signal, type = 'id') => {
                    if (type === 'name') {
                        const query = urlQueryBuilder({ ...queryParams, filter_by: type });
                        return nxAxios.get(`/api/user/invoice${query}`, { signal });
                    }

                    const id = queryParams.filter;
                    delete queryParams.filter;

                    const query = urlQueryBuilder(queryParams);

                    return nxAxios.get(`/api/user/invoice/${id}${query}`, { signal });
                },
            },

            single: {
                /**
                 * Gets the details for a single invoice
                 */
                GET: (invoice_id) => {
                    return nxAxios.get(`/api/user/invoice/${invoice_id}`);
                },

                apply_credit: {
                    /**
                     * CCP needs to be able to apply credit to an existing invoice
                     * @type {AccountAPI.User.Invoice.Single.ApplyCredit.PUT.TMethod}
                     */
                    PUT: (invoice_id) => {
                        return nxAxios.put(`/api/user/invoice/${invoice_id}/apply-credit`);
                    },
                },

                cancel: {
                    /**
                     * Cancel an Invoice
                     */
                    POST: (invoice_id, action = null) => {
                        function actionPayload() {
                            return action ? { type: 'invoice', attributes: { action } } : {};
                        }

                        return nxAxios.post(`/api/user/invoice/${invoice_id}/cancel`, actionPayload());
                    },
                },

                download: {
                    /**
                     * Get downloadable invoice in responseType `blob`
                     */
                    POST: (invoice_id) => {
                        return nxAxios.post(
                            `/api/user/invoice/${invoice_id}/download`,
                            {
                                type: 'invoice',
                                id: invoice_id,
                            },
                            { responseType: 'blob' }
                        );
                    },
                },

                email: {
                    /**
                     * Sends user an email of the invoice
                     */
                    POST: (invoice_id) => {
                        return nxAxios.post(`/api/user/invoice/${invoice_id}/email`, {
                            type: 'invoice',
                            id: invoice_id,
                        });
                    },
                },

                extend: {
                    /**
                     * Extend the invoice
                     */
                    POST: (invoice_id) => {
                        return nxAxios.post(`/api/user/invoice/${invoice_id}/extend`, {
                            type: 'invoice',
                            id: invoice_id,
                        });
                    },
                },
                pay: {
                    /**
                     * Pay for an invoice
                     */
                    POST: ({ invoiceID, payment_method_id, payment_method_data_id, payment_data }) => {
                        const paymentAttributes = {};

                        if (payment_method_id) paymentAttributes.payment_method_id = payment_method_id;

                        if (payment_method_data_id) paymentAttributes.payment_method_data_id = payment_method_data_id;

                        if (payment_data) paymentAttributes.payment_data = payment_data;

                        return nxAxios.post(`/api/user/invoice/${invoiceID}/pay`, {
                            type: 'invoice',
                            attributes: paymentAttributes,
                        });
                    },
                },
                split: {
                    /**
                     * Splits the invoice
                     */
                    POST: ({ invoiceID, itemIds }) => {
                        const attributes = {
                            itemIds,
                        };
                        return nxAxios.post(`/api/user/invoice/${invoiceID}/split`, {
                            ...attributes,
                        });
                    },

                    preview: {
                        /**
                         * Display a split preview for the invoice
                         */
                        POST: ({ invoiceID, itemIds }) => {
                            const attributes = {
                                itemIds,
                            };
                            return nxAxios.post(`/api/user/invoice/${invoiceID}/split/preview`, {
                                ...attributes,
                            });
                        },
                    },
                },
            },
        },
        invoice_offset: {
            /**
             * Sets the new invoice offset
             * @param {number} days
             */
            POST: (days) => {
                return nxAxios.post(`/api/user/invoice-offset`, {
                    type: 'invoice-offset',
                    attributes: {
                        ...days,
                    },
                });
            },
        },
        auth: {
            code: {
                GET: (): API.user.auth.code.ReturnType => {
                    return nxAxios.post(`/api/user/auth/code`);
                },
            },
        },
    },

    account: {
        GET: {
            mercury: {
                bypass: (token: API.account.GET.mercury.bypass.Params): API.account.GET.mercury.bypass.ReturnType => {
                    return nxAxios.get(`/api/login/bypass/${token}`);
                },
            },

            security: {
                question: () => {
                    return axios.get('/api/user/security');
                },

                list: () => {
                    return axios.get('/api/list/security-questions');
                },
            },

            history: {
                active: () => {
                    return axios.get('/api/user/login-history/active');
                },

                expired: () => {
                    return axios.get('/api/user/login-history/expired');
                },
            },

            service: {
                align: () => {
                    return axios.get(`/api/service/align`);
                },
            },

            serviceGroups: {
                list: ({ pageParam }: { pageParam: number }): API.account.GET.serviceGroups.list.ReturnType => {
                    return nxAxios.get(`/api/service-groups?page=${pageParam}`);
                },
            },

            sla: {
                event: () => {
                    return axios({
                        method: 'get',
                        url: `/api/user/sla-event`,
                        cancelToken: new CancelToken(function executor(c) {
                            // An executor function receives a cancel function as a parameter
                            cancelGetSLAEvents = c;
                        }),
                    });
                },

                services: (event_id) => {
                    return axios({
                        method: 'get',
                        url: `/api/user/sla-claim/${event_id}`,
                        cancelToken: new CancelToken(function executor(c) {
                            // An executor function receives a cancel function as a parameter
                            cancelGetSLAServices = c;
                        }),
                    });
                },
            },

            email: {
                list: (page) => {
                    if (page) {
                        return axios.get(`/api/user/emails?page=${page}`);
                    } else {
                        return axios.get(`/api/user/emails`);
                    }
                },

                search: (keyword) => {
                    return axios({
                        method: 'get',
                        url: `/api/user/emails/search/${keyword}`,
                        cancelToken: new CancelToken(function executor(c) {
                            // An executor function receives a cancel function as a parameter
                            cancelSearchAccountEmails = c;
                        }),
                    });
                },

                details: (id) => {
                    return axios.get(`/api/user/emails/${id}`);
                },
            },

            bypass: (email) => {
                const encodedEmail = encodeURIComponent(email);
                return axios.get(`/api/login/bypass/?email=${encodedEmail}`);
            },

            user: () => {
                return axios.get('/api/user');
            },

            password: () => {
                return axios.get('/api/user/password');
            },

            subaccount: {
                /**
                 * @returns {Artah.User.AdditionalUser.GET.All.Promise}
                 */
                list: () => {
                    return axios.get(`/api/user/additional-user`);
                },

                exists: (token: string): API.account.GET.subaccount.exists.ReturnType => {
                    return nxAxios.get(`/api/user/additional-user/user-exists?token=${token}`);
                },

                approve: (token: string): API.account.GET.subaccount.approve.ReturnType => {
                    return nxAxios.get(`/api/user/additional-user/approve?token=${token}`);
                },
            },

            permission: () => {
                return axios.get('/api/user/permission');
            },

            activate: (token) => {
                return axios.get(`/api/activate/account/${token}`);
            },

            twofactor: () => {
                return axios({
                    method: 'get',
                    url: `/api/user/two-factor`,
                    cancelToken: new CancelToken(function executor(c) {
                        CancelGetTwoFactor = c;
                    }),
                });
            },

            qrCode: () => {
                return axios.get('/api/qr/generate?enable_2fa=1');
            },

            paymentMethod: () => {
                return axios.get(`/api/user/payment-method`);
            },

            accountCredit: () => {
                return axios.get(`/api/account/account-credit`);
            },

            subscription: () => {
                return axios.get('/api/user/subscription');
            },

            country: () => {
                return axios.get('/api/country');
            },

            /**
             * Get a list of states for a given country
             * @param {string} [country]
             * @returns {Artah.LocalState.ID.GET.All.Promise}
             */
            states: (country) => {
                if (country) {
                    return axios({
                        method: 'get',
                        url: '/api/local-state/' + country,
                        cancelToken: new CancelToken(function executor(c) {
                            // An executor function receives a cancel function as a parameter
                            cancelGetStates = c;
                        }),
                    });
                }
                return axios({
                    method: 'get',
                    url: '/api/local-state/',
                    cancelToken: new CancelToken(function executor(c) {
                        // An executor function receives a cancel function as a parameter
                        cancelGetStates = c;
                    }),
                });
            },

            contact: () => {
                return axios({
                    method: 'get',
                    url: `/api/user/contacts`,
                    cancelToken: new CancelToken(function executor(c) {
                        cancelGetContact = c;
                    }),
                });
            },

            wallet: {
                balances: () => axios.get(`/api/wallet`),

                transactions: (params) =>
                    axios({
                        method: 'get',
                        url: `/api/wallet/transactions${urlQueryBuilder(params)}`,
                        cancelToken: new CancelToken(function executor(c) {
                            // An executor function receives a cancel function as a parameter
                            cancelGetWalletTransactions = c;
                        }),
                    }),

                searchTransactions: (type, term) =>
                    axios({
                        method: 'get',
                        url: `/api/wallet-transactions/search/${term}${type ? `?type=${type}` : ''}`,
                        cancelToken: new CancelToken(function executor(c) {
                            // An executor function receives a cancel function as a parameter
                            cancelSearchWalletTransactions = c;
                        }),
                    }),

                cashOut: (type, customAmount) =>
                    axios({
                        method: 'get',
                        url: `/api/wallet/${type}${customAmount ? `?is_custom_amount=true&amount=${customAmount}` : '?is_custom_amount=false'}`,
                        cancelToken: new CancelToken(function executor(c) {
                            // An executor function receives a cancel function as a parameter
                            cancelCalculateCashOut = c;
                        }),
                    }),
            },
        },

        POST: {
            contact: {
                add: (attributes) => {
                    return axios.post(`/api/user/contacts/create`, {
                        type: 'account-contact',
                        data: [
                            {
                                type: 'account-contact',
                                attributes,
                            },
                        ],
                    });
                },

                delete: (id) => {
                    return axios.post(`/api/user/contacts/delete`, {
                        type: 'account-contact',
                        data: [
                            {
                                type: 'account-contact',
                                id,
                            },
                        ],
                    });
                },
            },

            subaccount: {
                add: (attributes) => {
                    return axios.post('/api/user/additional-user', {
                        type: 'additional-user',
                        attributes,
                    });
                },

                signup: (attributes, token) => {
                    return axios.post(`/api/user/additional-user/approve-signup?token=${token}`, {
                        type: 'additional-user',
                        attributes,
                    });
                },

                resendInvitation: (email) => {
                    return axios.post(`/api/user/additional-user/invitation/resend`, {
                        type: 'additional-user',
                        attributes: {
                            email,
                        },
                    });
                },
            },

            activate: {
                user: (token, attributes) => {
                    return axios.post(`/api/activate/account/${token}/detail`, {
                        type: 'activation',
                        attributes,
                    });
                },

                security: (token, attributes) => {
                    return axios.post(`/api/activate/account/${token}/security`, {
                        type: 'activation',
                        attributes,
                    });
                },
            },

            email: {
                resend: (id) => {
                    return axios.post(`/api/user/emails/${id}/resend`, {
                        type: 'email',
                        id,
                    });
                },
            },

            security: {
                verify: (type, security_code) => {
                    return axios.post('/api/security/verify', {
                        type,
                        attributes: {
                            security_code,
                        },
                    });
                },

                generate: (type) => {
                    return axios.post('/api/security/generate', {
                        type,
                    });
                },
            },

            twofactor: {
                verify: (token, is_primary) => {
                    if (is_primary === undefined || is_primary === null) {
                        return axios.post('/api/user/two-factor/verify', {
                            type: 'two-factor',
                            attributes: {
                                two_factor_token: token,
                            },
                        });
                    } else {
                        return axios.post('/api/user/two-factor/verify', {
                            type: 'two-factor',
                            attributes: {
                                two_factor_token: token,
                                is_primary: is_primary,
                            },
                        });
                    }
                },
            },

            service: {
                alignPreview: (attributes) => {
                    return axios.post(`/api/service/align/preview`, {
                        ...attributes,
                    });
                },

                align: (attributes) => {
                    return axios.post(`/api/service/align`, {
                        ...attributes,
                    });
                },
            },

            serviceGroup: {
                add: (params: API.account.POST.serviceGroup.add.Params): API.account.POST.serviceGroup.add.ReturnType => {
                    return nxAxios.post('api/service-groups', {
                        type: 'service-groups',
                        attributes: params,
                    });
                },
            },

            passwordReset: (token, attributes) => {
                return axios.post(`/api/forgot/password/${token}`, {
                    type: 'forgot',
                    attributes: {
                        ...attributes,
                    },
                });
            },

            claim: ({ hosting_id, event_id, comments }) => {
                return axios.post(`/api/user/sla-claim/${event_id}`, {
                    type: 'sla-claim',
                    id: event_id,
                    attributes: {
                        hosting_id,
                        comments,
                    },
                });
            },

            resend: (id) => {
                return axios.post(`/api/user/emails/${id}/resend`, {
                    type: 'email',
                    id,
                });
            },

            close: () => {
                return axios.post(`/api/user/close`);
            },

            wallet: {
                addCredit: (payload) => axios.post(`/api/wallet/add-credit`, payload),

                cashOut: (payload) => axios.post(`/api/wallet/cash-out`, payload),
            },
        },

        PUT: {
            contact: {
                update: (id, attributes) => {
                    return axios.put(`/api/user/contacts/update`, {
                        type: 'account-contact',
                        data: [
                            {
                                type: 'account-contact',
                                id,
                                attributes,
                            },
                        ],
                    });
                },
            },

            security: {
                first: (attributes) => {
                    return axios.put('/api/user/security/first-time', {
                        type: 'security',
                        attributes,
                    });
                },

                question: (attributes) => {
                    return axios.put('/api/user/security', {
                        type: 'security-question',
                        attributes,
                    });
                },
            },

            update: {
                user: (attributes) => {
                    return axios.put('/api/user', {
                        type: 'user-profile',
                        attributes,
                    });
                },

                password: (old_password, password, password_confirmation) => {
                    return axios.put('/api/user/password', {
                        type: 'password',
                        attributes: {
                            old_password,
                            password,
                            password_confirmation,
                        },
                    });
                },
            },

            twofactor: {
                set: (id) => {
                    return axios.put(`/api/user/two-factor/${id}/set-primary`, {
                        type: 'two-factor',
                        id,
                    });
                },

                configure: (id, attributes) => {
                    return axios.put(`/api/user/two-factor/${id}/configure`, {
                        type: 'two-factor',
                        id,
                        attributes,
                    });
                },

                enable: (attributes) => {
                    return axios.put(`/api/user/two-factor/enable`, {
                        type: 'two-factor',
                        attributes,
                    });
                },
            },

            subaccount: {
                update: (id, permission) => {
                    return axios.put(`/api/user/additional-user/${id}`, {
                        type: 'additional-user',
                        attributes: {
                            group: permission,
                        },
                    });
                },
            },

            serviceGroup: {
                edit: ({
                    name,
                    description,
                    serviceGroupId,
                }: API.account.PUT.serviceGroup.edit.Params): API.account.PUT.serviceGroup.edit.ReturnType => {
                    return nxAxios.put(`api/service-groups/${serviceGroupId}`, {
                        type: 'service-groups',
                        attributes: { name, description },
                    });
                },
            },

            email: (attributes) => {
                return axios.put('/api/user/email', {
                    type: 'user',
                    attributes,
                });
            },

            clientType: (attributes) => {
                return axios.put('/api/user/client-type', {
                    type: 'client-type',
                    attributes,
                });
            },

            /**
             * @param {{
             *   subscription: AccountAPI.account.PUT.subscription.Subscription,
             *   enable: AccountAPI.account.PUT.subscription.Enable
             * }} args
             */
            subscription({ subscription, enable }) {
                const map = {
                    emailPromotion: 'promotion',
                    smsPromotion: 'sms',
                    smsInvoice: 'sms_invoice',
                    smsNotifications: 'sms_domain',
                    smsSuspension: 'sms_suspend',
                };

                return axios.put(`/api/user/subscription/${map[subscription]}/${enable ? 'enable' : 'disable'}`, {
                    type: 'subscription',
                });
            },
        },

        DELETE: {
            history: {
                session: (id) => {
                    return axios.delete(`/api/user/login-history/${id}`);
                },

                all: () => {
                    return axios.delete(`/api/user/login-history/all`);
                },
            },

            subaccount: {
                delete: (id) => {
                    return axios.delete(`/api/user/additional-user/${id}`);
                },

                cancelInvitation: (email) => {
                    return axios.delete(`/api/user/additional-user/invitation/${email}`);
                },
            },

            serviceGroup: {
                delete: (
                    serviceGroupId: API.account.DELETE.serviceGroup.deleteGroup.Params
                ): API.account.DELETE.serviceGroup.deleteGroup.ReturnType => {
                    return nxAxios.delete(`api/service-groups/${serviceGroupId}`);
                },
            },

            accountAccess: (id) => {
                return axios.delete(`/api/user/account-access/${id}`);
            },

            twofactor: (id) => {
                return axios.delete(`/api/user/two-factor/${id}`);
            },
        },
    },
};
