import { getPaymentMethodData } from 'containers/billing/queries/queries/getPaymentMethodData';
import { billingMutationKeys } from 'containers/billing/queries/queryKeys';
import { API } from 'utilities/api/account';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';
import { createNXMutation } from 'utilities/methods/tanstack/createMutation';

/**********************************************************************************************************
 *   MUTATION START
 **********************************************************************************************************/
export const removePaymentMethodData = createNXMutation({
    mutationKey: billingMutationKeys.removePaymentMethodData(),
    mutationFn: API.user.payment_method_data.single.DELETE,
    onSuccess: (response) => {
        handleDefaultSuccessNotification(response);
        getPaymentMethodData.invalidate();
    },
});
