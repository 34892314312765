import { billingQueryKeys } from 'containers/billing/queries/queryKeys';
import { API } from 'utilities/api/account';
import { createNXQuery } from 'utilities/methods/tanstack/createQuery';

/**********************************************************************************************************
 *   QUERY START
 **********************************************************************************************************/
export const getPayPalToken = createNXQuery((id: number) => ({
    queryKey: billingQueryKeys.payPalToken(id),
    queryFn: () => API.user.payment_method.generate.POST(id),
}));
