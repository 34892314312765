/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useQuery } from '@tanstack/react-query';

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { API as ACCOUNT_API } from 'utilities/api/account';
import { getDataFromData } from 'utilities/methods/commonActions';
import { handleDefaultErrorNotification } from 'utilities/methods/commonActions/handleDefaultErrorNotification';
import { createQueryBoilerPlate } from 'utilities/methods/tanstack';

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 */
const getUserPaymentMethodDataListBoilerPlate = createQueryBoilerPlate({
    service: 'billing',
    serviceKey: 'paymentMethodData',
    APIRoute: () => () => ACCOUNT_API.user.payment_method_data.GET(),
    defaultOptions: {
        staleTime: 60_000,
        onError: () => handleDefaultErrorNotification,
        retryOnMount: false,
        retry: 3,
    },
});

const { getDefaults } = getUserPaymentMethodDataListBoilerPlate;

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * @deprecated use v5 format, see katanaQuery
 * User Payment Method Data (list of Method Data, credit cards etc)
 */
function useGetUserPaymentMethodDataListQuery(options = {}) {
    return useQuery({
        ...getDefaults(),
        ...options,
    });
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**
 * Filters replace Select
 */
useGetUserPaymentMethodDataListQuery.selectFilters = {
    /**
     * @deprecated use v5 format, see katanaQuery
     * Gets the specific payment method from the payment method data type
     */
    getPaymentMethodType: (paymentMethod) => {
        return (response) => {
            const responseData = getDataFromData(response);
            return responseData?.filter?.(({ attributes: { payment_method } }) => payment_method.name === paymentMethod);
        };
    },
};

export { getUserPaymentMethodDataListBoilerPlate, useGetUserPaymentMethodDataListQuery };
