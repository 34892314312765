import eftImage from 'assets/images/eft.jpeg';
import classNames from 'classnames';
import { Radio } from 'components/Form/Radio';
import type { SelectedPaymentMethodProps } from 'components/Lightboxes/OverlayLightbox/Components/invoice/paymentMethods/types';
import { NXQuery } from 'utilities/query';
import { PAYMENT_METHODS } from './consts';

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const EftPaymentMethod: React.FC<SelectedPaymentMethodProps> = ({ selectedPaymentMethod, setSelectedPaymentMethod }) => {
    /***** HOOKS *****/
    const { data: account_number } = NXQuery.auth.userData.useSelectSuspenseQuery(void 0, ({ data }) => data.attributes.account_number);
    const { data: eft_payment_data } = NXQuery.billing.getAvailablePaymentMethods.useSelectSuspenseQuery(void 0, ({ data }) =>
        data.find((paymentMethod) => paymentMethod?.name === 'EFT')
    );

    /***** RENDER HELPERS *****/
    const isEFTSelected = selectedPaymentMethod === PAYMENT_METHODS.EFT;

    /***** RENDER *****/
    return (
        <div key="eft" className={classNames('method', { 'method--selected': isEFTSelected })}>
            <Radio.Circle.Button
                className="method__top"
                isActive={isEFTSelected}
                color="primary"
                onClick={() => setSelectedPaymentMethod(PAYMENT_METHODS.EFT)}
            >
                <div className="method__top--title">
                    <div className="label">EFT Transfer</div>
                </div>
                <div className="method__top--img">
                    <img className="cc_icon eft" src={eftImage} alt="eft" />
                </div>
            </Radio.Circle.Button>
            {isEFTSelected ? (
                <div className="method__bottom eft__wrapper">
                    <div className="eft__row">
                        <div className="label">Account Name</div>
                        <div className="value">{eft_payment_data?.module_details.name}</div>
                    </div>
                    <div className="eft__row">
                        <div className="label">BSB</div>
                        <div className="value">{eft_payment_data?.module_details.bsb}</div>
                    </div>
                    <div className="eft__row">
                        <div className="label">Account Number</div>
                        <div className="value">{eft_payment_data?.module_details.account_number}</div>
                    </div>
                    <div className="eft__row">
                        <div className="label">Description</div>
                        <div className="value desc">{account_number}</div>
                    </div>
                    <div className="eft__note note">
                        Please note we recommend allowing 1-2 business days for payments to process (this may be further delayed by weekends and
                        public holidays).
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
