import { createBaseQueryKey } from 'utilities/methods/tanstack/createBaseQueryKey';

/**********************************************************************************************************
 *   QUERY KEY FACTORY START
 **********************************************************************************************************/
export const billingQueryKeys = Object.assign(() => createBaseQueryKey(['billing']), {
    paymentMethodData: () => [...billingQueryKeys(), 'paymentMethodData'] as const,
    defaultPaymentMethod: () => [...billingQueryKeys(), 'defaultPaymentMethod'] as const,
    availablePaymenthMethods: () => [...billingQueryKeys(), 'availablePaymentMethods'] as const,
    payPalToken: (id: number) => [...billingQueryKeys(), 'payPalToken', id] as const,
});

export const billingMutationKeys = Object.assign(() => createBaseQueryKey(['billing']), {
    addCreditCard: () => [...billingMutationKeys(), 'addCreditCard'] as const,
    removePaymentMethodData: () => [...billingMutationKeys(), 'removePaymentMethodData'] as const,
    setDefaultPaymentMethod: () => [...billingMutationKeys(), 'setDefaultPaymentMethod'] as const,
    setPaymentMethodCallback: () => [...billingMutationKeys(), 'setPaymentMethodCallback'] as const,
    setPrimaryCreditCard: () => [...billingMutationKeys(), 'setPrimaryCreditCard'] as const,
});
