import { billingQueryKeys } from 'containers/billing/queries/queryKeys';
import { API } from 'utilities/api/account';
import { createNXQuery } from 'utilities/methods/tanstack/createQuery';

/**********************************************************************************************************
 *   QUERY START
 **********************************************************************************************************/
export const getDefaultPaymentMethod = createNXQuery(() => ({
    queryKey: billingQueryKeys.defaultPaymentMethod(),
    queryFn: API.user.payment_method.GET,
}));
