import { getPaymentMethodData } from 'containers/billing/queries/queries/getPaymentMethodData';
import { billingMutationKeys } from 'containers/billing/queries/queryKeys';
import { API } from 'utilities/api/account';
import { handleDefaultSuccessNotification } from 'utilities/methods/commonActions/handleDefaultSuccessNotification';
import { createNXMutation } from 'utilities/methods/tanstack/createMutation';

/**********************************************************************************************************
 *   MUTATION START
 **********************************************************************************************************/
export const addCreditCard = createNXMutation({
    mutationKey: billingMutationKeys.addCreditCard(),
    mutationFn: API.user.creditCard.POST,
    onSuccess: (response) => {
        handleDefaultSuccessNotification(response);
        getPaymentMethodData.invalidate();
    },
});
